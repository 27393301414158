import { IConfig } from 'configs/config.interface';

export const CONFIG: IConfig = {
    Type: 'preprod',
    API: {
        ContainerTracking: 'https://api-preprod.damco.com/containertracking/api',
        Customs: 'https://api-preprod.damco.com/customs/api',
        DeliveryPlanning: 'https://api-preprod.damco.com/deliveryplanning/api',
        VesselSchedule: 'https://api-preprod.damco.com/vessel-schedule/api',
        FactWrapper: 'https://api-preprod.damco.com/fact-wrapper/api',
        Presentation: 'https://api-preprod.damco.com/presentation/api',
        LogicApps: {
            EditVessel: 'https://api-preprod.damco.com/containertracking/api/edit-vessel',
            EditVesselDetails: 'https://api-preprod.damco.com/containertracking/api/edit-vessel-details',
            EditVesselDetailsOnCBLLevel: 'https://api-preprod.damco.com/containertracking/api/edit-vesseldetails-on-cbl-level',
            SendTransportOrderToHaulier: 'https://api-preprod.damco.com/deliveryplanning/api/send-transport-order-to-haulier',
            UpdateEquipmentDetails: 'https://api-preprod.damco.com/deliveryplanning/api/update-equipment-details',
            UpdateEmptyReturnEquipmentDetails: 'https://api-preprod.damco.com/deliveryplanning/api/update-empty-return-equipment-details',
            UpdateEmptyReturnActuals: 'https://api-preprod.damco.com/deliveryplanning/api/update-empty-return-actuals',
            UpdateHBLDetails: 'https://api-preprod.damco.com/customs/api/update-hbl-details',
            NotifyCustomer: 'https://api-preprod.damco.com/containertracking/api/notify-customer',
            AvailableForBooking: 'https://api-preprod.damco.com/deliveryplanning/api/update-avaialable-for-booking',
            NotifyCustomerForCustoms: 'https://api-preprod.damco.com/customs/api/NotifyCustomer',
        }
    },
    MSAL: {
        instance: 'https://maerskappsDevelopment.b2clogin.com/tfp',
        tenant: 'maerskappsDevelopment.onmicrosoft.com',
        signInPolicy: 'B2C_1A_Base01_Ext01_eCOM_SignIn01',
        applicationId: '4f0b6054-6d21-4e71-b9cb-2db67b2a6316',
        cacheLocation: 'localStorage',
        scopes: ['openid'],
        redirectUri: 'https://destinationmgmt-preprod.damco.com',
        postLogoutRedirectUri: 'https://destinationmgmt-preprod.damco.com',
        storeAuthStateInCookie: true,
    },
};
